<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-body class="p-0">
      <b-row>
        <b-col
            v-for="field in fields"
            :key="field.name"
            cols="6"
        >
          <field
              :field.sync="field.val"
              :name="field.name"
              :options="field.value ? Object.values(field.value) : []"
              :type="field.type"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>

import {BCardBody} from "bootstrap-vue"
import Field from "@/components/contract/form/Field.vue"
import {FieldsTypeEnum} from "@/enums/index";

export default {
  components: {
    Field,
    BCardBody,
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    fields: {
      type: Array,
      required: true,
    },
    contractFields: {
      type: Array,
      default: null,
      required: false,
    }
  },
  created() {
    this.createCustomFields()
  },
  methods: {
    createCustomFields() {
      this.fields.forEach((field) => {
        const defaultVal = field.type === FieldsTypeEnum.NUMBER ? 0 : ""
        if (this.contractFields) {
          const matchField = this.contractFields.find(f => f.field_id === field.id)
          if (matchField) {
            if (JSON.parse(matchField.value).value) this.$set(field, 'val', JSON.parse(matchField.value).value); else this.$set(field, 'val', defaultVal)
          } else {
            this.$set(field, 'val', defaultVal)
          }
        } else {
          this.$set(field, 'val', defaultVal)
        }
      })
    },
  }
}
</script>
<style>
.col-form-label{
  margin-bottom: 0.2857rem;
  padding-bottom: 0;
  font-size: 0.857rem;
}
</style>
