<template>
  <b-form-group
      :label="name"
  >
    <v-select
        v-model="fieldModel"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </b-form-group>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fieldModel: {
      get() {
        return this.field
      },
      set(value) {
        this.$emit('update:field', value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
