<template>
  <b-form-group
      :label="name"
      :label-for="name"
  >
    <b-form-textarea
        :id="name"
        v-model="fieldModel"
        :placeholder="name"
        :state="errors.length > 0 ? false:null"
        trim
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </b-form-group>
</template>
<script>

import {BFormTextarea} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  props: {
    field: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    fieldModel: {
      get() {
        return this.field
      },
      set(value) {
        this.$emit('update:field', value)
      },
    },
  },
}
</script>
