<template>
  <b-card>
    <b-form-group
        :label="$i18n.t('user.contracts.duration')"
    >
      <v-select
          v-model="durationType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="options"
          :reduce="item => item.value"
          label="label"
          @input="clearData"
      />
    </b-form-group>
    <b-row>
      <b-col cols="6">
        <date-field
            :field.sync="startDateField"
            :name="startDateName"
        />
      </b-col>
      <b-col
          cols="6"
          v-if="durationOption === DurationType.WITHOUT_END_DATE || durationOption === DurationType.WITH_END_DATE_AUTO_RENEWAL"
      >
        <number-field
            :field.sync="noticePeriodInMonthsField"
            :name="noticePeriodInMonthsName"
        />
      </b-col>
      <b-col
          cols="6"
          v-if="durationOption === DurationType.WITH_END_DATE_WITHOUT_AUTO_RENEWAL || durationOption === DurationType.WITH_END_DATE_AUTO_RENEWAL "
      >
        <number-field
             :field.sync="durationInMonthsField"
            :name="durationInMonthsName"
        />
      </b-col>
      <b-col
          cols="6"
          v-if="durationOption === DurationType.WITH_END_DATE_AUTO_RENEWAL"
      >
        <date-field
            :field.sync="lastPossibleTerminationDateField"
            :name="lastPossibleTerminationDateName"
        />
      </b-col>
      <b-col
          cols="6"
          v-if="durationOption === DurationType.WITH_END_DATE_AUTO_RENEWAL || durationOption === DurationType.WITH_END_DATE_WITHOUT_AUTO_RENEWAL"
      >
        <date-field
            :field.sync="endDateField"
            :name="endDateName"
        />
      </b-col>
      <b-col
          cols="6"
          v-if="durationOption === DurationType.WITH_END_DATE_AUTO_RENEWAL"
      >
        <number-field
            :field.sync="automaticRenewalPeriodInMonthsField"
            :name="automaticRenewalPeriodInMonthsName"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {BCard} from "bootstrap-vue";
import {DurationType} from "@/enums/index";
import DateField from "./form/fields-type/Date.vue"
import NumberField from "./form/fields-type/Number.vue"

export default {
  components: {
    DateField,
    NumberField
  },
  props: {
    startDate: {
      type: String,
      required: true
    },
    durationOption: {
      type: Number,
      default: null,
      required: false
    },
    durationInMonths: {
      type: Number,
      default: null,
      required: false
    },
    lastPossibleTerminationDate: {
      type: String,
      default: null,
      required: false
    },
    noticePeriodInMonths: {
      type: Number,
      default: null,
      required: false
    },
    endDate: {
      type: String,
      default: null,
      required: false
    },
    automaticRenewalPeriodInMonths: {
      type: Number,
      default: null,
      required: false
    },
  },
  data() {
    return {
      startDateName: this.$i18n.t("user.contracts.durationOptions.startDate"),
      durationInMonthsName: this.$i18n.t("user.contracts.durationInMonthsName"),
      noticePeriodInMonthsName: this.$i18n.t("user.contracts.noticePeriodInMonthsName"),
      automaticRenewalPeriodInMonthsName: this.$i18n.t("user.contracts.automaticRenewalPeriodInMonthsName"),
      endDateName: this.$i18n.t("user.contracts.endDateName"),
      lastPossibleTerminationDateName: this.$i18n.t("user.contracts.lastPossibleTerminationDateName"),
      options: [
        {label: this.$i18n.t("user.contracts.durationOptions.startDate"), value: 0},
        {label: this.$i18n.t("user.contracts.durationOptions.withEndDateAndWithoutAutomaticRenewal"), value: 1},
        {label: this.$i18n.t("user.contracts.durationOptions.withEndDateAndAutomaticRenewal"), value: 2},
        {label: this.$i18n.t("user.contracts.durationOptions.withoutEndDate"), value: 3}
      ],
      DurationType,
      config: {
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
    }
  },
  computed: {
    durationType: {
      get() {
        return JSON.parse(JSON.stringify(this.durationOption))
      },
      set(value) {
        this.$emit('update:durationOption', value)
      },
    },
    startDateField: {
      get() {
        return JSON.parse(JSON.stringify(this.startDate))
      },
      set(value) {
        this.$emit('update:startDate', value)
      },
    },
    durationInMonthsField: {
      get() {
        return JSON.parse(JSON.stringify(this.durationInMonths))
      },
      set(value) {
        this.$emit('update:durationInMonths', value)
      },
    },
    lastPossibleTerminationDateField: {
      get() {
        return JSON.parse(JSON.stringify(this.lastPossibleTerminationDate))
      },
      set(value) {
        this.$emit('update:lastPossibleTerminationDate', value)
      },
    },
    noticePeriodInMonthsField: {
      get() {
        return JSON.parse(JSON.stringify(this.noticePeriodInMonths))
      },
      set(value) {
        this.$emit('update:noticePeriodInMonths', value)
      },
    },
    endDateField: {
      get() {
        return JSON.parse(JSON.stringify(this.endDate))
      },
      set(value) {
        this.$emit('update:endDate', value)
      },
    },
    automaticRenewalPeriodInMonthsField: {
      get() {
        return JSON.parse(JSON.stringify(this.automaticRenewalPeriodInMonths))
      },
      set(value) {
        this.$emit('update:automaticRenewalPeriodInMonths', value)
      },
    },
  },
  methods: {
    clearData() {
      this.durationInMonthsField = 0
      this.lastPossibleTerminationDateField = ''
      this.noticePeriodInMonthsField = 0
      this.endDateField = ''
      this.automaticRenewalPeriodInMonthsField = 0
    }
  }
}
</script>

<style scoped>

</style>
