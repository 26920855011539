<template>
  <b-row>
    <b-col
        cols="12"
        md="12"
    >
      <b-button
          class="mb-2 float-md-right width-200"
          type="submit"
          variant="primary"
          @click="save()"
      >
        {{ $route.params.id ? $i18n.t('update') : $i18n.t('save') }}
      </b-button>
    </b-col>
    <b-col
        cols="12"
        md="6"
    >
      <b-card>
        <b-row>
          <b-col>
            <b-form-group
                :label="$i18n.t('user.contracts.title')"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="contractTitle"
                  trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                :label="$i18n.t('admin.category.category')"
            >
              <v-select
                  v-model="categoryId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categories"
                  :reduce="val => val.id"
                  label="name"
                  :clearable="false"
                  @input="getSections"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                :label="$i18n.t('user.contracts.status')"
            >
              <v-select
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statuses"
                  :reduce="item => item.value"
                  label="title"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <Duration
          :automatic-renewal-period-in-months.sync="automaticRenewalPeriodInMonths"
          :duration-in-months.sync="durationInMonths"
          :duration-option.sync="durationOption"
          :end-date.sync="endDate"
          :last-possible-termination-date.sync="lastPossibleTerminationDate"
          :notice-period-in-months.sync="noticePeriodInMonths"
          :start-date.sync="startDate"
      />
      <b-card>
        <b-form-group
            :label="$i18n.t('user.contracts.tags')"
        >
          <b-form-tags
              v-model="tags"
              :placeholder="$i18n.t('user.contracts.addNewTag')"
              class="mb-2"
              input-id="tags-basic"
          />
        </b-form-group>
      </b-card>
      <section-card
          v-for="section in sections"
          :key="`section${section.id}`"
          :contract-fields="contractFields"
          :fields.sync="section.fields"
          :title="section.name"
      />
    </b-col>
    <b-col>
      <upload-pdf
          v-if="isCanDo('updateContract')"
          :contract-id="contractId"
          :is-exist-pdf="isExistPdf"
          :type="$route.params.id ? 'update' : 'create'"
      />
    </b-col>
  </b-row>
</template>

<script>
import {BFormTags} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SectionCard from "@/components/contract/form/SectionCard.vue"
import RepositoryFactory from "@/repositories/RepositoryFactory"
import {ContractStatus} from "@/enums"
import uploadPdf from "@/components/contract/uploadPdf.vue";
import Duration from "@/components/contract/Duration.vue";

export default {
  components: {
    SectionCard,
    BFormTags,
    uploadPdf,
    Duration
  },
  data() {
    return {
      durationOption: 0,
      startDate: '',
      durationInMonths: 0,
      noticePeriodInMonths: 0,
      automaticRenewalPeriodInMonths: 0,
      endDate: '',
      lastPossibleTerminationDate: '',
      categoryId: null,
      categories: [],
      sections: [],
      statuses: [],
      tags: [],
      contractId: null,
      contractFields: null,
      status: ContractStatus.ACTIVE,
      contractTitle: "default contract",
      categoryRepository: RepositoryFactory.create('categories'),
      contractRepo: RepositoryFactory.create('contracts'),
      isExistPdf: null,
    }
  },
  created() {
    this.getCategories()
    if (this.$route.params.id) this.getContract()
  },
  methods: {
    getCategories() {
      this.utilsAppRepository().getCategories().then(res => {
        this.categories = res.data.data
        if (!this.$route.params.id) {
          this.categoryId = this.categories[0]?.id
          this.getSections()
          this.createContract()
        }
        this.getStatuses()
      })
    },
    getContract() {
      this.contractId = this.$route.params.id
      this.contractRepo.get(this.contractId).then(res => {
        this.categoryId = res.data.data.category.id
        this.status = res.data.data.status
        this.sections = res.data.data.category.sections
        this.contractTitle = res.data.data.title
        this.contractFields = res.data.data.fields
        this.durationOption = res.data.data.durationType
        this.startDate = res.data.data.startDate ? res.data.data.startDate : ''
        this.noticePeriodInMonths = res.data.data.noticePeriod ? res.data.data.noticePeriod : 0
        this.durationInMonths = res.data.data.duration ? res.data.data.duration : 0
        this.automaticRenewalPeriodInMonths = res.data.data.renewalPeriod ? res.data.data.renewalPeriod : 0
        this.endDate = res.data.data.endDate ? res.data.data.endDate : ''
        this.lastPossibleTerminationDate = res.data.data.lastPossibleTerminationDate ? res.data.data.lastPossibleTerminationDate : ''
        this.tags = res.data.data.tags ? JSON.parse(res.data.data.tags) : null
        this.tags = res.data.data.tags ? JSON.parse(res.data.data.tags) : null
        this.isExistPdf = res.data.data.isExistPdf
      });
    },
    createContract() {
      const payload = {
        team_id: this.getCurrentTeam().id,
        title: this.contractTitle,
        category_id: this.categoryId,
      }
      this.contractRepo.store(payload).then(response => {
        this.contractId = response.data.data.contractId
      })
    },
    getStatuses() {
      this.utilsAppRepository().getContractStatus()
          .then(res => {
            this.statuses = res.data.data
          })
    },
    getSections() {
      this.categoryRepository.getCategorySections(this.categoryId)
          .then(res => {
            this.sections = res.data.data
          })
    },
    createPayload() {
      const data = {
        title: this.contractTitle,
        status: this.status,
        category_id: this.categoryId,
        tags: this.tags.length !== 0 ? JSON.stringify(this.tags) : null,
        fields: this.formContractFields(),
        type: this.$route.params.id ? 'update' : 'create',
        durationType: this.durationOption,
        duration: this.durationInMonths,
        renewalPeriod: this.automaticRenewalPeriodInMonths,
        noticePeriod: this.noticePeriodInMonths
      }
      return this.addDurationDate(data)
    },
    formContractFields() {
      const fields = []
      this.sections.forEach(element => {
        element.fields.forEach(field => {
          const val = {
            name: field.name,
            value: field.val
          }
          const obj = {
            field_id: field.id,
            value: JSON.stringify(val)
          }
          fields.push(obj)
        })
      })
      return fields
    },
    save() {
      const payload = this.createPayload()
      this.contractRepo.update(this.contractId, payload)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('Success'),
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message
              },
            })
            this.$router.push({name: 'show-contract', params: {id: this.contractId}});
          })
    },
    addDurationDate(data) {
      if (this.startDate !== '') this.$set(data, 'startDate', this.startDate); else this.$set(data, 'startDate', null)
      if (this.endDate !== '') this.$set(data, 'endDate', this.endDate); else this.$set(data, 'endDate', null)
      if (this.lastPossibleTerminationDate !== '') this.$set(data, 'lastPossibleTerminationDate', this.lastPossibleTerminationDate); else this.$set(data, 'lastPossibleTerminationDate', null)
      return data;
    }
  }
}
</script>
