<template>
  <div>
    <text-field
        v-if="type === FieldsTypeEnum.SINGLE_LINE_TEXT"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
    />

    <number-field
        v-if="type === FieldsTypeEnum.NUMBER"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
    />

    <textarea-field
        v-if="type === FieldsTypeEnum.MULTI_LINE_TEXT"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
    />

    <date-field
        v-if="type === FieldsTypeEnum.DATE"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
    />

    <list-field
        v-if="type === FieldsTypeEnum.LIST"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
        :options="options"
    />

    <link-field
        v-if="type === FieldsTypeEnum.LINK"
        :errors="errors"
        :field.sync="fieldModel"
        :name="name"
    />
  </div>
</template>
<script>
import {FieldsTypeEnum} from "@/enums/index";
import TextField from "@/components/contract/form/fields-type/Text.vue"
import NumberField from "@/components/contract/form/fields-type/Number.vue"
import ListField from "@/components/contract/form/fields-type/List.vue"
import TextareaField from "@/components/contract/form/fields-type/Textarea.vue"
import DateField from "@/components/contract/form/fields-type/Date.vue"
import LinkField from "@/components/contract/form/fields-type/Link.vue"

export default {
  components: {
    TextField,
    ListField,
    NumberField,
    TextareaField,
    DateField,
    LinkField,
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    field: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      FieldsTypeEnum
    }
  },
  computed: {
    fieldModel: {
      get() {
        return this.field
      },
      set(value) {
        this.$emit('update:field', value)
      },
    },
  }
}
</script>
