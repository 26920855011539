<template>
  <div class="form-group date-field">
    <label>{{ name }}</label>
    <div class="input-group">
      <flat-pickr
          v-model="fieldModel"
          :config="config"
          :name="name"
          class="form-control"
      />
    </div>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    flatPickr,
  },
  props: {
    field: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      config: {
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },

    }
  },
  computed: {
    fieldModel: {
      get() {
        return JSON.parse(JSON.stringify(this.field))
      },
      set(value) {
        this.$emit('update:field', value)
      },
    },
  },
}
</script>
<style>
.date-field .form-control[readonly]{
  background-color: transparent !important;
  border-radius: 0.357rem !important;
}
</style>
